import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import axios from 'axios';

import DayeHeader from '../components/Nav';
import { D } from '@dayetopia/library';
import dropIcon from '../images/drop-icon.png';
import { Context } from '../components/Context';
import pharmacyUrl from '../utils/urls';

export default function CheckingHistory() {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    const sessionId = localStorage.getItem('session-id');
    axios // fetch was returning a readable stream so using axios instead
      .get(
        `${pharmacyUrl(
          window.location.hostname
        )}/sessions/${sessionId}/recommendation`
      )
      .then(({ data }) => {
        dispatch({ type: 'setRecommendation', data: data });
        setTimeout(() => {
          navigate('/recommendation');
        }, 2000);
      })
      .catch(err => console.log('err', err));
  }, []);
  return (
    <PageContainer>
      <PageContent>
        <ImgStyled src={dropIcon} />
        <H2Styled>Checking your medical history...</H2Styled>
        <PStyled>Do not close this page before complete loading.</PStyled>
      </PageContent>
    </PageContainer>
  );
}
const ImgStyled = styled.img`
  width: 214px;
  height: 233.5px;
  margin-bottom: 50px;
`;
const PStyled = styled(D.P)`
  font-family: BrownStd;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #00391e;
  opacity: 0.6;
`;
const H2Styled = styled(D.H2)`
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: #00391e;
`;
const PageContainer = styled.div`
  height: 100vh;
  position: relative;
  background: #eaf5e8;
`;

const PageContent = styled.div`
  display: flex;
  height: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadein 0.8s;
`;
